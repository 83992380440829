@font-face {
  font-family: 'custom-serif';
  font-style: normal;
  font-weight: 700;
  src: url('./../assets/type/custom-extra-bold.woff') format('woff');
}

@font-face {
  font-family: 'eina_04-bold';
  font-weight: normal;
  font-style: normal;
  src: url('./../assets/type/eina04-bold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'eina_04-semibold';
  font-weight: bold;
  font-style: normal;
  src: url('./../assets/type/eina04-semibold-webfont.woff') format('woff');
}
@mixin mediamax($max) {
  @media (max-width: $max) {
    @content;
  }
}
@mixin mediamin($min) {
  @media (min-width: $min) {
    @content;
  }
}
@mixin header-height {
  height: 100px;
}

@mixin bartransition {
  @for $i from 1 through 6 {
    &:nth-child(#{$i}) .progress-bar.appear-done {
      transition: width 0.2s #{$i * 0.1}s ease-out;
    }
  }
}
@mixin bartransition2 {
  @for $i from 1 through 6 {
    &:nth-child(#{$i}) .progress-bar.appear-done {
      transition: width 0.2s #{$i * 0.1 + 0.5}s ease-out;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
figure {
  margin: 0;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}
html {
  height: 100%;
}

body,
#root,
.app {
  min-height: 100vh;
}

a {
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
}

body {
  font-family: 'eina_04-semibold', 'custom-serif', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  color: white;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
label,
input,
p,
a {
  color: white;
}

.topics,
aside {
  border-right: 2px solid #{'rgba(var(--white), 0.05)'};
}
button {
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid hsla(0, 0%, 100%, 0.3);
  padding: 12px 40px;
  border-radius: 60px;
  font-size: 1rem;
  font-family: inherit;
  &:focus {
    outline: none;
  }
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  font-size: 1rem;
  font-family: inherit;
  text-decoration: none;
  font-family: 'eina_04-semibold', 'custom-serif', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  &-small {
    padding: 1px 11px 0px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin-left: 18px;
    transform: translateY(-2px);
    &:hover {
      background: hsla(0, 0%, 100%, 0.3);
    }
  }
  &-primary {
    background: var(--brand-red);
    padding: 12px 40px 15px;
    font-size: 20px;
    border: none;
    width: 84%;
    margin: 60px auto 40px;

    img {
      margin: 1px 0 0 5px;
    }
    max-width: 350px;
    box-shadow: 0 0 0 0 hsla(0, 100, 75, 0),
      0 10px 25px 0 rgba(230, 112, 112, 0.5);
    &:hover {
      box-shadow: 0 0px 20px hsla(0, 100, 75, 0.6),
        0 10px 25px hsla(0, 100, 75, 0.7);
      transition: box-shadow 0.1s;
    }
  }
}
.app {
  background: var(--theme-blue);
  overflow: auto;
  min-height: 100vh;
  position: relative;
  &:before {
    content: '';
    display: block;
    background-color: transparent;
    background-size: 5px 100%;
    background-image: linear-gradient(90deg, white 1px, transparent 2px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    opacity: 0.03;
  }
}

.preheader {
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: -100px;
}
.logo {
  pointer-events: none;
  background: #ff8181;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 50%;
    height: 50%;
  }
}
.topics {
  display: flex;
  display: none;
}

header,
aside,
main {
  padding-left: 5%;
  padding-right: 5%;
  padding: 30px 5% 30px;
  h3 {
    opacity: 0.7;
  }
}

header {
  padding: 25px;
  padding-left: 100px;
  position: relative;
  h2 {
    margin: 0 0 3px;
  }
  h3 {
    margin: 0;
    font-size: 15px;
    color: var(--bgrey);
    text-transform: uppercase;
  }
  .theme-selector {
    position: absolute;
    right: 5%;
    top: 120px;
    text-align: center;
    z-index: 2;
    > span {
      font-size: 10px;
      margin: 0 auto 5px;
      text-transform: uppercase;
      opacity: 0.8;
      display: block;
    }
  }
}
.wrapper {
  position: relative;
}
aside {
  font-family: fira-mono, monospace;
  font-weight: 400;

  .mugshot {
    width: 100px;
    height: 100px;
    background: var(--bgrey1);
    border-radius: 100%;
    font-size: 0px;
    margin: 0 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      border-radius: 50px;
      width: 100px;
      height: 100px;
    }
  }
  .tech-apps {
    display: grid;
    margin: 10px auto 40px;
    justify-content: flex-start;
    grid-template-columns: auto auto auto auto auto auto;
    align-items: center;
    margin-left: -10px;
    img {
      margin: 0 12px 15px;
    }
  }

  .profile {
    margin-bottom: 20px;
    a {
      font-size: 1rem;
      display: inline-block;
      position: relative;
      overflow: hidden;
      text-decoration: none;
      border-bottom: 1px solid white;
      span {
        z-index: 1;
        position: relative;
      }
      &:before {
        content: '';
        background: hsla(0, 100, 75, 0.5);
        transform: translateY(30px);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
      }
      &:hover {
        &:before {
          transform: translateY(0px);
          transition: transform 0.1s ease-out;
        }
      }
    }
    a,
    p {
      margin-top: 0;
    }
    &.info-email p {
      letter-spacing: 1px;
      text-decoration: none;
      display: inline-block;
      &:before {
        content: attr(data-1) '' attr(data-2) '' attr(data-3) '' attr(data-4) ''
          attr(data-5);
        display: block;
      }
    }
  }
  h3 {
    margin-bottom: 4px;
    font-size: 16px;
  }
}

main {
  margin-bottom: 100px;
  h1 {
    font-family: 'custom-serif', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 60px;
    margin: 0 auto 50px;
    opacity: 0;
    animation: fadeIn 0.3s forwards;
    span {
      display: block;

      &:first-child {
        color: var(--theme-aqua);
      }
      &:nth-child(2) {
        display: block;
      }
    }
  }
  h2 {
    margin-bottom: 60px;
    line-height: 1.5em;
    animation: fadeIn 0.3s 0.1s forwards;
    font-size: 1.2rem;
  }
  .page-content {
    opacity: 0;
    animation: fadeIn 0.2s 0.3s forwards;
  }
}
.skills {
  margin-bottom: 60px;
  &-dev {
    .skill {
      @include bartransition;
    }
  }
  &-des {
    .skill {
      @include bartransition2;
    }
  }
  &-wrap {
    h3 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    h4 {
      margin-top: 0;
      margin-bottom: 40px;
      font-size: 30px;
    }
    .skill {
      margin-bottom: 15px;
      width: 100%;
    }
  }
  h5 {
    margin-bottom: 5px;
  }
  .progress-bar-bg {
    height: 9px;
    border-radius: 2px;
    background-color: var(--whitebg);
    display: block;
    width: 100%;
    background-color: transparent;
    background-size: 7px 9px;
    background-image: linear-gradient(
      90deg,
      var(--theme-white-bg) 40%,
      transparent 40%
    );
  }

  .progress-bar {
    //transition: width 1s 1s ease-in-out;
    height: 9px;
    border-radius: 2px 0 0 2px;
    background-color: var(--theme-aqua);
    display: block;
    background-color: transparent;
    background-size: 7px 9px;
    background-image: linear-gradient(
      90deg,
      var(--theme-aqua) 40%,
      transparent 40%
    );
    //filter: drop-shadow(0px 0px 6px var(--theme-aqua));

    &.appear {
      width: 0;
    }
    &.appear-done {
      width: 100%;
    }
  }
  span#xxx {
    width: var(---pwidth);
  }
}

@include mediamax(420px) {
  header {
    h2 {
      font-size: 1.3rem;
    }
    h3 {
      font-size: 0.9rem;
    }
  }
  main {
    h1 {
      font-size: 3.1rem;
    }
  }
}
@include mediamin(570px) {
  header {
    .theme-selector {
      top: 20px;
    }
  }
}
@include mediamin(768px) {
  button.primary {
    width: auto;
    padding: 12px 40px;
  }
  .ios-switch {
    height: 30px;
    width: 70px;
    &-knob {
      left: 46px;
      height: 20px;
      width: 20px;
      top: 5px;
    }
    &-off {
      .ios-switch-knob {
        left: 5px;
      }
    }
    &-on {
      .ios-switch-knob {
        left: 45px;
      }
    }
  }
  .wrapper {
    display: flex;
  }
  aside {
    max-width: 440px;
    padding: 30px 3% 30px 3%;
    .profile {
      margin-bottom: 50px;
    }
  }
  main {
    padding: 30px 4% 30px 4%;
  }
}

.ios-switch {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  height: 30px;
  width: 70px;
  display: flex;
  transition: order 1s ease;
  padding: 5px 5px;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  &-text {
    order: 2;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  span {
    order: 2;
    cursor: default;
    font-size: 10px;
    text-transform: uppercase;
    padding: 0 3px;
    opacity: 0.5;
    user-select: none;
  }
  &-knob {
    background: white;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    flex: 0 0 auto;
  }
  &-off {
    .ios-switch-knob {
      order: 1;
    }
  }
  &-on {
    .ios-switch-knob {
      order: 2;
    }
  }
}
@include mediamin(950px) {
  h1 {
    span {
      font-size: 68px;
      display: block;

      &:first-child {
        color: var(--theme-aqua);
      }
      &:nth-child(2) {
        display: block;
      }
      &:last-child {
        display: block;
        //  margin-left: 80px;
      }
    }
  }
  .skills {
    &-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10%;
      flex-grow: 1;
    }
    .progress-bar {
      filter: drop-shadow(0px 0px 6px var(--theme-aqua-glow));
    }
  }
}
@include mediamax(1200px) {
  header {
    border-bottom: 2px solid #{'rgba(var(--white), 0.05)'};
  }
  .light {
    header {
      border-bottom: 2px solid #{'rgba(var(--black), 0.05)'};
    }
  }
}
@include mediamin(1200px) {
  header {
    margin-bottom: -101px;
    padding-left: 100px;
    h2 {
      font-size: 1.2rem;
    }
  }

  aside,
  main {
    padding-top: 200px;
    max-width: 1400px;
  }
  main {
    padding: 180px 10% 30px;
  }
}
@include mediamin(1440px) {
  header {
    padding-left: calc(100px + 3%);
    .theme-selector {
      top: 60px;
    }
  }
  h1 {
    span {
      font-size: 68px;
      display: block;
      margin-left: -20%;
      &:first-child {
        color: var(--theme-aqua);
        margin-left: -15%;
      }
      &:nth-child(2) {
        display: block;
        margin-left: -15%;
      }
      &:last-child {
        display: block;
        margin-left: 0;
        margin-left: -15%;
      }
    }
  }
  aside {
    min-height: calc(100vh - 100px);
  }
  button.primary {
    padding: 12px 70px;
  }
  main {
    h1 {
      padding-left: 12%;
      margin-bottom: 70px;
    }
    h2 {
      padding-left: 20%;
    }
  }
  section {
    display: flex;
    h3 {
      min-width: 20%;
      margin-top: 5px;
    }
  }
  .topics {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    min-width: 100px;

    min-height: calc(100vh - 100px);
    div {
      transform: rotate(-90deg);
      opacity: 0.2;
    }
  }
  .skills {
    .skill {
      margin-bottom: 25px;
    }
  }
}
@include mediamin(1540px) {
  h1 {
    span {
      font-size: 130px;
    }
  }
  main {
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 200px;
    h2 {
      margin-bottom: 90px;
      font-size: 1.3rem;
    }
  }
  section {
    margin-bottom: 30px;
  }
}

.work-history {
  border-bottom: 1px solid rgba(var(--white), 0.1);
  padding-bottom: 70px;
  h4 {
    margin-top: 0;
    font-size: 1.2rem;
  }
  .description {
    font-size: 1rem;
    span,
    a {
      opacity: 0.5;
    }
    a {
      &:hover {
        text-decoration: underline;
        opacity: 1;
      }
    }
  }
  a {
    text-decoration: none;
  }
  p,
  li {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
  p {
    opacity: 0.5;
  }
  .company-list {
    list-style: none;
    margin: 0;
    padding: 10px 0 0;
  }
  .company-list > li {
    padding-bottom: 50px;
    padding-left: 35px;
    clear: left;
    margin: 0 0 0;
    overflow: hidden;
    position: relative;
    &:last-child {
      padding-bottom: 0;
      &::after {
        border: none;
      }
    }
    &:before {
      opacity: 0.5;
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: white;
    }
    &:after {
      opacity: 0.3;
      content: '';
      position: absolute;
      left: 4px;
      top: 25px;
      bottom: 0;
      border-left: 1px dashed white;
    }
    ul {
      opacity: 0.5;
      li {
        list-style: square;
        position: relative;
      }
    }
  }
}

.app.light {
  background: var(--theme-red-bg);
  &:before {
    background-color: transparent;
    background-size: 5px 100%;
    background-image: linear-gradient(
      90deg,
      rgb(196, 155, 155) 1px,
      transparent 2px
    );
    opacity: 0.1;
  }
  body {
    color: white;
  }
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  input,
  ul,
  li,
  p,
  a {
    color: var(--theme-red);
  }
  header,
  aside,
  main {
    h3 {
      opacity: 0.7;
    }
  }
  header {
    h3 {
      color: var(--theme-red);
    }
    .theme-selector {
      > span {
        color: var(--theme-red);
      }
    }
  }
  main h1 span {
    color: var(--theme-red);
  }
  .topics,
  aside {
    border-right: 2px solid #{'rgba(var(--black), 0.05)'};
    .profile {
      a {
        border-color: var(--theme-red);
      }
    }
  }
  .skills {
    .progress-bar-bg {
      height: 7px;
      border-radius: 5px;
      background: var(--theme-red-lighter);
    }
    .progress-bar {
      height: 7px;
      border-radius: 5px;
      background: var(--theme-red);
      filter: none;
    }
  }
  .button {
    &-small {
      border: 2px solid var(--theme-red-light);
      color: var(--theme-red);
      &:hover {
        background: var(--theme-red-light);
      }
    }
    &-primary {
      box-shadow: none;
      background: var(--theme-red);
      span {
        color: white;
      }
      &:hover {
        background: var(--brand-red);
        opacity: 0.8;
      }
    }
  }

  .work-history {
    a,
    span,
    p {
      opacity: 0.6;
    }

    .company-list {
      & > li ul,
      p {
        opacity: 0.6;
      }
      li:before,
      li:after {
        background: var(--theme-red);
        opacity: 0.8;
      }
    }
  }

  .ios-switch {
    background: var(--theme-red-light);
    &:hover {
      background: var(--theme-red);
    }
    span {
      color: white;
      opacity: 1;
    }
  }
}
