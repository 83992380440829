:root {
  --brand-red: #ff8181;
  --border-light: #ffffff09;
  --white: 250, 250, 250;
  --black: 0, 0, 0;

  //themedark
  --theme-blue: #2133d6;
  --theme-white-bg: #ffffff1e;
  --theme-aqua: #00e0ff;
  --theme-aqua-glow: #00e1ff7a;

  //themelight
  --theme-red-bg: #ffeeee;
  --theme-red: #5b3c40;
  --theme-red-light: #5b3c407c;
  --theme-red-lighter: #5b1a1a1e;
}
